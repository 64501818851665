import Header from './Header';
import './App.css';
import MonthPoint from './MonthPoint';


export default function App() {

  return (
    <div className="app">
      <Header />
      <MonthPoint year={2024} month={10} />
      <div style={{ marginTop: '5rem' }}>
        注意事項
        <p>
          税込価格に対してポイントを算出する「[SPU]楽天カード 通常分」のような稀なキャンペーンがあります。
          これらのキャンペーンに対して、本アプリでは税抜価格で算出していますので、獲得できるポイントと誤差が発生します。<br />
          現時点で、税込価格に対するポイント算出に対応する予定はありません。
        </p>
      </div>
    </div>
  );
}
